<template>
  <b-modal
    v-model="showModal"
    v-if="certificate.certificate"
    size="lg"
    static
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    hide-footer
  >
    <div class="modal-header">
      <h5 class="modal-title">{{ `Details Certificate ${certificate.certificate.name} (${certificate.vehicle.name})` }}</h5>
      <button type="button" class="close" @click="$emit('close')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <DetailCertificate :certificate="certificate"/>

    <EndorsementCertificate v-if="certificate.certificate.certificateType !=='PROVISIONAL'" :certificate="certificate"/>

    <fieldset class="form-group border p-3" v-if="certificate.certificate.certificateType !=='PROVISIONAL'">
      <legend class="w-auto px-2">Reminders:</legend>
      <b-row>
        <b-col md="12">
          <ul type="dot">
            <li
              v-for="(reminder, index_reminder) in JSON.parse(
                certificate.certificate.reminder
              )"
              :key="`reminder-${index_reminder}`"
            >
              {{ findReminder(reminder) }}
            </li>
          </ul>
        </b-col>
      </b-row>
    </fieldset>

    <fieldset class="form-group border p-3" v-if="certificate.certificate.certificateType !=='PROVISIONAL'">
      <legend class="w-auto px-2">Notification Receiver:</legend>
      <b-row class="mt-4 mb-4">
        <b-col
          md="3"
          class="text-center"
          v-for="(user, index_user) in certificate.user"
          :key="`user-${index_user}`"
        >
          <div class="custom-control">
            <label class="" for="ck1b">
              <img
                :src="require('@assets/images/flaticon/crewing.png')"
                alt="#"
                class="img-fluid"
              />
            </label>
            <p
              ><small
                ><strong>{{ user.name }}</strong></small
              ></p
            >
          </div>
        </b-col>
      </b-row>
    </fieldset>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ModalDetailCertificate',
  components: {
    DetailCertificate: () => import('@components/certificate/DetailCertificate'),
    EndorsementCertificate: () => import('@components/certificate/EndorsementCertificate')
  },
  props: {
    reminders: {
      type: Array,
      default: []
    }
  },
  computed: {
    ...mapState('certificates', {
      modal_detail: (state) => state.modal_detail,
    }),
    showModal() {
      return this.modal_detail.show
    },
    certificate() {
      return this.modal_detail.certificate
    }
  },
  methods: {
    findReminder(reminder_value) {
      let reminder = this.reminders.find((r) => r.value == reminder_value)
      if (reminder) return reminder.text
      return '-'
    },
  }
}
</script>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.certificate.certificate ? _c('b-modal', {
    attrs: {
      "size": "lg",
      "static": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "hide-header": "",
      "hide-footer": ""
    },
    model: {
      value: _vm.showModal,
      callback: function callback($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('div', {
    staticClass: "modal-header"
  }, [_c('h5', {
    staticClass: "modal-title"
  }, [_vm._v(_vm._s("Details Certificate ".concat(_vm.certificate.certificate.name, " (").concat(_vm.certificate.vehicle.name, ")")))]), _c('button', {
    staticClass: "close",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('span', {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("×")])])]), _c('DetailCertificate', {
    attrs: {
      "certificate": _vm.certificate
    }
  }), _vm.certificate.certificate.certificateType !== 'PROVISIONAL' ? _c('EndorsementCertificate', {
    attrs: {
      "certificate": _vm.certificate
    }
  }) : _vm._e(), _vm.certificate.certificate.certificateType !== 'PROVISIONAL' ? _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2"
  }, [_vm._v("Reminders:")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('ul', {
    attrs: {
      "type": "dot"
    }
  }, _vm._l(JSON.parse(_vm.certificate.certificate.reminder), function (reminder, index_reminder) {
    return _c('li', {
      key: "reminder-".concat(index_reminder)
    }, [_vm._v(" " + _vm._s(_vm.findReminder(reminder)) + " ")]);
  }), 0)])], 1)], 1) : _vm._e(), _vm.certificate.certificate.certificateType !== 'PROVISIONAL' ? _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2"
  }, [_vm._v("Notification Receiver:")]), _c('b-row', {
    staticClass: "mt-4 mb-4"
  }, _vm._l(_vm.certificate.user, function (user, index_user) {
    return _c('b-col', {
      key: "user-".concat(index_user),
      staticClass: "text-center",
      attrs: {
        "md": "3"
      }
    }, [_c('div', {
      staticClass: "custom-control"
    }, [_c('label', {
      attrs: {
        "for": "ck1b"
      }
    }, [_c('img', {
      staticClass: "img-fluid",
      attrs: {
        "src": require('@assets/images/flaticon/crewing.png'),
        "alt": "#"
      }
    })]), _c('p', [_c('small', [_c('strong', [_vm._v(_vm._s(user.name))])])])])]);
  }), 1)], 1) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }